import dayjs from 'dayjs';

/**
 * 格式化为YYYY.MM.DD HH:mm:ss
 * @param time 时间字符串
 */
export const YMDHms = (time: string) =>
  dayjs(time).format('YYYY.MM.DD HH:mm:ss');

/**
 * 格式化为YYYY.MM.DD
 * @param time 时间字符串
 */
export const YMD = (time: string) => dayjs(time).format('YYYY.MM.DD');

/**
 * 类型验证集合，判断输入值的业务类型
 */
export const valid = {
  isNumber: (_: string) => /^[1-9][0-9]*$/.test(_),
  isAddress: (_: string) => _?.length === 42,
  isTxHash: (_: string) => _?.length === 66,
};

/**
 * 交易类型key-value的映射表，对pause和unpause做了特殊处理
 */
export const TRANSACTION_TYPE: { [key: string]: string } = {
  deployContract: 'deploy',
  updatePrice: 'modifyPrice',
  increaseStore: 'modify',
  decreaseStore: 'modify',
  publish: 'modify',
  burn: 'burn',
  batchBurn: 'burn',
  batchTransferFrom: 'transfer',
  batchTransferWithSignature: 'transfer',
  transfer: 'transfer',
  transferFrom: 'transfer',
  transferWithSignature: 'transfer',
  transferSpecifiedWithSignature: 'transfer',
  batchMint: 'mint',
  mint: 'mint',
  orderForUser: 'createC2C',
  makeOrder: 'createC2C',
  cancelOrder: 'cancelC2C',
  tradeForUser: 'buyC2C',
  takerOrderWithTokenIds: 'buyC2C',
  takeOrder: 'buyC2C',
  verify: 'verify',
  buy: 'buyOfficial',
  applies: 'createApply',
  cancel: 'cancelApply',
  refund: 'refund',
  // pause
  'pause-COUPON': 'pause',
  'pause-GRC20': 'pause',
  'pause-EXCHANGEMARKET20': 'modify',
  'pause-EXCHANGEMARKET721': 'modify',
  'pause-COUPONVERIFICATION': 'modify',
  'pause-SALE721': 'pauseSell',
  'pause-CONPONREFUND': 'modify',
  // unpause
  'unpause-COUPON': 'unpause',
  'unpause-GRC20': 'unpause',
  'unpause-EXCHANGEMARKET20': 'modify',
  'unpause-EXCHANGEMARKET721': 'modify',
  'unpause-COUPONVERIFICATION': 'modify',
  'unpause-SALE721': 'onSale',
  'unpause-CONPONREFUND': 'modify',
};

/**
 * 交易类型的中文映射
 */
export const TRANSACTION_NAME: { [key: string]: string } = {
  '': '-',
  deploy: '合约部署',
  modify: '合约修改',
  pause: '合约冻结',
  unpause: '合约解冻',
  burn: '资产销毁',
  transfer: '资产转移',
  mint: '资产铸造',
  verify: '资产核销',
  createC2C: '创建C2C交易',  // todo: 挂单，购买
  cancelC2C: '取消C2C交易',
  createApply: '申请退款',
  cancelApply: '撤销申请退款',
  buyC2C: '资产C2C购买',
  buyOfficial: '资产官方购买',
  refund: '退款',
  others: '手续费转账',
  onSale: '开售',
  pauseSell: '暂停出售',
  modifyPrice: '修改价格',
};

/**
 * 所有合约
 * COUPON
 * EXCHANGEMARKET20
 * EXCHANGEMARKET721
 * SALE721
 * COUPONVERIFICATION
 * CONPONREFUND
 * ARTWORK
 * SYNTH
 * DIGITALIP
*/

/**
 * 资产合约的类型，数组
 */
export const ASSET_CONTRACT_TYPE = ['COUPON', 'GRC20', 'ARTWORK', 'DIGITALIP'];

/**
 * 业务合约的类型，数组
 */
export const BUSINESS_CONTRACT_TYPE = [
  'EXCHANGEMARKET20',
  'EXCHANGEMARKET721',
  'SALE721',
  'COUPONVERIFICATION',
  'CONPONREFUND',
  'SYNTH',
];

/**
 * 返回交易类型的英文映射
 * @param _ 传入trading_record，下有属性input_detail和contract
 */
export const getTransactionType = (_: any) => {
  if (_.status === 'FAIL') {
    return  '';
  }
  if (!_.input_detail) {
    return 'others';
  }
  if (_.input_detail.name === 'pause' || _.input_detail.name === 'unpause') {
    return TRANSACTION_TYPE[
      `${_.input_detail.name}-${_.contract.contract_type}`
    ];
  }

  return TRANSACTION_TYPE[_.input_detail.name] || 'others';
};

/**
 * 返回交易类型的中文映射
 * @param _ 传入trading_record，下有属性input_detail和contract
 */
export const getTransactionName = (_: string) =>
  TRANSACTION_NAME[getTransactionType(_)];

/**
 * 判断合约是资产还是业务，true为资产合约
 * @param type 传入contract_type
 */
export const isAssetContract = (type: string) => {
  if (ASSET_CONTRACT_TYPE.some((_) => _ === type)) {
    return '资产合约';
  }

  return '业务合约';
};

/**
 * 判断资产类型
 * @param type 传入contract_type
 */
export const getAssetType = (type: string) => {
  const assetType = {
    takerOrderWithTokenIds: '卡券',
    COUPON: '卡券',
    ARTWORK: '数字文创',
    DIGITALIP: '数字文创',
    GRC20: '积分',
  } as any;

  return assetType[type];
};

export const transactionDetails = (record: any, type: string) => {
  const { contract, event_details, input_detail } = record;
  const detail = input_detail?.details;

  const DETAIL_MAP = {
    updatePrice: `修改资产${detail?.nft}售价为${detail?.price}GT`,
    increaseStore: `修改资产${detail?.nft}在售数量为${detail?.store}`,
    decreaseStore: `修改资产${detail?.nft}在售数量为${detail?.store}`,
    publish: `上架资产${detail?.nft}，售价为${detail?.price}，在售数量为${detail?.total}`,
    pause: `修改资产${detail?.nft}状态为停售`,
    unpause: `修改资产${detail?.nft}状态为起售`,
  } as any;

  const buyInfo = Array.isArray(event_details) && event_details.length > 0 ?
                    event_details[event_details.length - 1].details :
                    {};

  const TRANSACTION_TYPE =  {
    /**
     * 业务合约
     * 开售           onSale                0x29a41de23cddd97e4f4c8fa0853ecf576b5652f1b3b8ee4b7ce2ff94c9070473
     * 修改价格        modifyPrice           0x686e4292019f45817e4ee591932f4dce10862e4d71e5e0ad4a0ae8dd80d206ce
     * 暂停出售        pauseSell             0x9808ee5afa3dfebb7716ce4126ba29047e08c5653e4eab1c39d56aebe642f2a9
     * 资产官方购买     buyOfficial           0x52449f26f8dca0ac62c8479d39b3794abcecb5e3e6349df8427897fe645f9e82
     * 申请退款        createApply           0x70dcd013588954cb9388e6532be97b365dcc9d748f33d2c3c887d834f6c690a9
     * 撤销申请退款     cancelApply           0x12a18d5a2d4a355caeece980e2f3f8fe6d7cc95e27697427752ca47e1821cf16
     * 退款            refund                0xdcea09d6b9d160c1b15dc2cf2d031f51c3520b381a8941b13c5ac49f21d21812
     * 资产核销         verify                0x7dba771ed700d79f921b5f0fb69fed069a6da4338f76bc550b7617a72c3bf1c3
     * 创建C2C交易      createC2C             TODO：grc20, grc21
     *                                       0xec23666eac35e9a39f010eefa140eeea9b882d98156fda03e3cd2b05d814ef22
     *                                       0xff66a60ae976c27bcb15bcbd77982cd9cca65acdc93c6c1495ddbcccadc838c2
     * 取消C2C交易      cancelC2C             TODO: grc20, grc21
     *                                      0x3ce702cd6e0c6268a091b3706b5a379c73718bb8b0fe549c794cb72c604f088b
     *                                      0x6f155cd76750cfe7efce513a2b44a961f3208f67602154e149bfd18a470e3327
     * 资产C2C购买      buyC2C                TODO: grc20, grc21
     *                                      0x3cd95b29fc8c330da730680bc1a6ce946ae487b10c16c5df184388af6d428ec0
     *                                      0x1cee4f7b976d122a353692d93a4e0acfd0494a97ac3370635b34e180af077228
     *
    */
    onSale: () => ({
      tx_type: ['交易类型', getTransactionName(record)],
      contract_address: ['合约地址', contract?.contract_address],
      contract_type: ['合约类型', isAssetContract(contract?.contract_type)],
      tx_detail: ['交易细节', DETAIL_MAP[input_detail?.name]],
    }),
    modifyPrice: () => ({
      tx_type: ['交易类型', getTransactionName(record)],
      contract_address: ['合约地址', contract?.contract_address],
      contract_type: ['合约类型', isAssetContract(contract?.contract_type)],
      tx_detail: ['交易细节', DETAIL_MAP[input_detail?.name]],
    }),
    pauseSell: () => ({
      tx_type: ['交易类型', getTransactionName(record)],
      contract_address: ['合约地址', contract?.contract_address],
      contract_type: ['合约类型', isAssetContract(contract?.contract_type)],
      tx_detail: ['交易细节', DETAIL_MAP[input_detail?.name]],
    }),
    buyOfficial: () => ({
      tx_type: ['交易类型', getTransactionName(record)],
      contract_address: ['合约地址', record.contract?.contract_address],
      asset_type: ['资产类型', getAssetType(getTokenInfo(record).asset_type)],
      asset_name: ['资产名称', getTokenInfo(record).asset_symbol || '-'],
      asset_symbol: ['资产品牌', getTokenInfo(record).asset_name || '-'],
      count: ['数量', getTokenInfo(record).count],
      tokens: ['TokenID', getTokenInfo(record).tokens || '-'], // TODO 数组展示
      amount: ['支付金额', `${getTokenInfo(record).amount} ${getTokenInfo(record).unit}`],
    }),
    createApply: () => ({
      tx_type: ['交易类型', getTransactionName(record)],
      contract_address: ['合约地址', contract?.contract_address],
      asset_type: ['资产类型', getAssetType(event_details[0].contract.contract_type)],
      asset_name: ['资产名称', event_details[0].contract.contract_symbol || '-'],
      asset_symbol: ['资产品牌', event_details[0].contract.contract_name || '-'],
      count: ['数量', buyInfo?.tokenIds?.split('|').length],
      tokens: ['TokenID', buyInfo?.tokenIds?.split('|')],
      amount: ['申请退款金额', input_detail?.details?.amountToDecimal],
    }),

    cancelApply: () => ({
      tx_type: ['交易类型', getTransactionName(record)],
      contract_address: ['合约地址', contract.contract_address],
      asset_type: ['资产类型', getAssetType(event_details[0].contract.contract_type)],
      asset_name: ['资产名称', event_details[0].contract.contract_symbol || '-'],
      asset_symbol: ['资产品牌', event_details[0].contract.contract_name || '-'],
      count: ['数量', buyInfo?.tokenIds?.split('|').length],
      tokens: ['TokenID', buyInfo?.tokenIds?.split('|')],
    }),


    refund: () => ({
      tx_type: ['交易类型', getTransactionName(record)],
      amount: ['退款金额', `${event_details[0].details.valueToDecimal} ${event_details[0].contract.contract_symbol}`], // TODO event_details
    }),

    verify: () => ({
      tx_type: ['交易类型', getTransactionName(record)],
      contract_address: ['合约地址', contract.contract_address],
      asset_type: ['资产类型', getAssetType(event_details[0].contract.contract_type)],
      asset_name: ['资产名称', event_details[0].contract.contract_symbol || '-'],
      asset_symbol: ['资产品牌', event_details[0].contract.contract_name || '-'],
      count: ['数量', buyInfo?.tokenId?.split('|').length],
      tokens: ['TokenID', buyInfo?.tokenId?.split('|')],
    }),

    createC2C: (() => {
      return event_details[0].contract.contract_type === 'GRC20' ? {
          tx_type: ['交易类型', getTransactionName(record)],
          contract_address: ['合约地址', contract.contract_address],
          asset_type: ['资产类型', getAssetType(event_details[0].contract.contract_type)],
          asset_name: ['资产名称', event_details[0].contract.contract_symbol || '-'],
          asset_symbol: ['资产品牌', event_details[0].contract.contract_name || '-'],
          count: ['数量', input_detail?.details?.amountGiveToDecimal?.split('|').length],
        } : {
          tx_type: ['交易类型', getTransactionName(record)],
          contract_address: ['合约地址', contract.contract_address],
          asset_type: ['资产类型', getAssetType(record.event_details[0].contract.contract_type)],
          asset_name: ['资产名称', event_details[0].contract.contract_symbol || '-'],
          asset_symbol: ['资产品牌', event_details[0].contract.contract_name || '-'],
          count: ['数量', input_detail?.details?.tokenIds?.split('|').length],
          tokens: ['TokenID', input_detail?.details?.tokenIds?.split('|')],
        };
     }),

    cancelC2C: (() => {

    return event_details[0].contract.contract_type === 'GRC20' ? {
      tx_type: ['交易类型', getTransactionName(record)],
      contract_address: ['合约地址', contract.contract_address],
      asset_type: ['资产类型', getAssetType(event_details[0].contract.contract_type)],
      asset_name: ['资产名称', event_details[0].contract.contract_symbol || '-'],
      asset_symbol: ['资产品牌', event_details[0].contract.contract_name || '-'],
      count: ['数量', event_details[0]?.details?.valueToDecimal],
    } : {
      tx_type: ['交易类型', getTransactionName(record)],
      contract_address: ['合约地址', contract.contract_address],
      asset_type: ['资产类型', getAssetType(event_details[0].contract.contract_type)],
      asset_name: ['资产名称', event_details[0].contract.contract_symbol || '-'],
      asset_symbol: ['资产品牌', event_details[0].contract.contract_name || '-'],
      count: ['数量', event_details[0]?.details?.tokenId?.split('|').length],
      tokens: ['TokenID', event_details[0]?.details?.tokenId?.split('|')],
    };
    }),

    buyC2C: (() => {
    return event_details[3]?.contract?.contract_type === 'GRC20' ? {
      tx_type: ['交易类型', getTransactionName(record)],
      contract_address: ['合约地址', contract?.contract_address],
      asset_type: ['资产类型', getAssetType(event_details[0]?.contract?.contract_type)],
      asset_name: ['资产名称', event_details[3]?.contract?.contract_symbol || '-'],
      asset_symbol: ['资产品牌', event_details[3]?.contract?.contract_name || '-'],
      count: ['数量', event_details[3]?.details?.valueToDecimal],
      amount: ['支付金额', `${event_details[0].details.valueToDecimal} ${event_details[0].contract.contract_symbol}`],
    } : {
      tx_type: ['交易类型', getTransactionName(record)],
      contract_address: ['合约地址', contract?.contract_address],
      asset_type: ['资产类型', getAssetType(input_detail.name)],
      asset_name: ['资产名称', event_details[3]?.contract?.contract_symbol || '-'],
      asset_symbol: ['资产品牌', event_details[3]?.contract?.contract_name || '-'],
      count: ['数量', event_details[3]?.details?.tokenIds?.split('|').length],
      amount: ['支付金额', `${event_details[0].details.valueToDecimal} ${event_details[0].contract.contract_symbol}`],
      tokens: ['TokenID', event_details[3]?.details?.tokenIds?.split('|')],
    };
    }),

    /**
     * 资产合约
     * 资产转帐         transfer           TODO: grc20 
     *                                    0x81f5f69650498f2fe8105523c41f54ca082885e3efab46f602c3823f5b6e0e04
     *                                    TODO: grc21
     *                                    0x2f2704dfdf64d9dcfacc552f02729b9a26c501b5fb6439610dd07f02aed7c80f
     * 转账            others             TODO: 原生币转账
     *                                    0x3fb4a423d596590237860424a19b9cdac2af47da7da1d1018a41ca48e8d37b4f
     * 资产增发         mint               TODO: grc20 grc21
     *                                    0xba28b8bb193bfb805a55e4033066ac91ba01ea1cc9da2723a49354abe9b8ba76
     *                                    0xdd6e071d24d99fc5eecaff785747502d23ae440829ef875661e27dd5908dcfde
     * 资产销毁         burn               TODO: grc20 grc21
     *                                    0x17721361a3c0e16fa70199c24d784e7ddfcda860c6e5300569aa5d82d28958a6
     *                                    0x7cf42f58c6396f20045b6429ecb7acbacb7d705a3965fd83529c06b5b0c209ea
     * 开启委托
     * 关闭委托
     * 配置Base URI
     */
     transfer: () => ({
        tx_type: ['交易类型', getTransactionName(record)],
        contract_address: ['合约地址', contract?.contract_address],
        asset_type: ['资产类型', getAssetType(contract?.contract_type)],
        asset_name: ['资产名称', contract?.contract_symbol || '-'],
        asset_symbol: ['资产品牌', contract?.contract_name || '-'],
        count: ['数量', event_details[0]?.details?.valueToDecimal || '-'],
     }),
     others: () => ({
      tx_type: ['交易类型', getTransactionName(record)]
     }),
     deploy: () => ({
      tx_type: ['交易类型', getTransactionName(record)]
     }),
     mint: (() => {
      if (event_details[0].contract.contract_type === 'ARTWORK') {
        return {
          tx_type: ['交易类型', getTransactionName(record)],
          contract_address: ['合约地址', contract?.contract_address],
          asset_type: ['资产类型', getAssetType(contract?.contract_type)],
          asset_name: ['资产名称', contract?.contract_symbol || '-'],
          asset_symbol: ['资产品牌', contract?.contract_name || '-'],
          count: ['数量', event_details[0]?.details?.tokenId?.split('|').length || 1],
          tokens: ['TokenID', event_details[0]?.details?.tokenId?.split('|')],
        };
      } else {
        return {
          tx_type: ['交易类型', getTransactionName(record)],
          contract_address: ['合约地址', contract?.contract_address],
          asset_type: ['资产类型', getAssetType(contract?.contract_type)],
          asset_name: ['资产名称', event_details[0]?.contract?.contract_symbol || '-'],
          asset_symbol: ['资产品牌', event_details[0]?.contract?.contract_name || '-'],
          count: ['数量', event_details[0]?.details?.valueToDecimal || 1],
        };
      }
     }),

     burn: (() => {
      if (event_details[0].contract.contract_type === 'GRC20') {
        return {
          tx_type: ['交易类型', getTransactionName(record)],
          contract_address: ['合约地址', contract?.contract_address],
          asset_type: ['资产类型', getAssetType(contract?.contract_type)],
          asset_name: ['资产名称', event_details[0]?.contract?.contract_symbol || '-'],
          asset_symbol: ['资产品牌', event_details[0]?.contract?.contract_name || '-'],
          count: ['数量', event_details[0]?.details?.valueToDecimal || '-'],
        };
      } else {
        return {
          tx_type: ['交易类型', getTransactionName(record)],
          contract_address: ['合约地址', contract?.contract_address],
          asset_type: ['资产类型', getAssetType(contract.contract_type)],
          asset_name: ['资产名称', event_details[0]?.contract?.contract_symbol || '-'],
          asset_symbol: ['资产品牌', event_details[0]?.contract?.contract_name || '-'],
          count: ['数量', event_details[0]?.details?.tokenId?.split('|') - 1 || '-'],
          tokens: ['TokenID', event_details[0]?.details?.tokenId?.split('|')],
        };
      }
     }),
  } as any;

  return TRANSACTION_TYPE[type] ? TRANSACTION_TYPE[type]() : null;
};
export const getTokenInfo = (record: any) => {
  let count = 0;
  const tokens = [] as any;
  let asset_name = '';
  let asset_symbol = '';
  let asset_type = '';
  let amount = 0;
  let unit = '';
  if (record.contract.contract_type === 'SALE721') {
    if (record.input_detail.name === 'buy') {
      const length = record.event_details.length;
      count = record.event_details[length - 1].details.amount;
      asset_name = record.event_details[0].contract.contract_name;
      asset_symbol = record.event_details[0].contract.contract_symbol;
      asset_type = record.event_details[0].contract.contract_type;
      for (let i = 0; i < count; i++) {
        tokens.push(record.event_details[i].details.tokenId);
      }

      const buyInfo = record.event_details[length - 2];
      unit = buyInfo.contract.contract_symbol;
      amount = buyInfo.details.valueToDecimal;
    }
  }

  return {
    count,
    tokens,
    amount,
    unit,
    asset_name,
    asset_type,
    asset_symbol,
  };
};
